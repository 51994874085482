import React from 'react';
import styled from 'styled-components/macro';
import HeadingTextImg from '../images/hero-logo.png';

const HeroSection = styled.section`
  height: 100vh;
  max-height: 1100px;
  position: relative;
  overflow: hidden;
`;

const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const HeroSlide = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before{
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73));
    // linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73));

    // background: linear-gradient(
    //   0deg, 
    //   rgba(0,0,0,0.5) 0%,
    //   rgba(0,0,0,0.5) 50%, 
    //   rgba(0,0,0,0.5) 100%
    // );
  }
`;

const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: calc(100% - 100px);
  color: #fff;

  h1 {
    color: #000;
  font-family: 'MigraBold';
  font-weight: normal;
  font-size: 1.6rem;

    // font-size: clamp(1rem, 8vw, 3.5rem);
    // font-weight: 600;
    // text-shadow: -1px 1px 0 #fff,
    //                       1px 1px 0 #fff,
    //                      1px -1px 0 #fff,
    //                     -1px -1px 0 #fff !important;
    /*text-transform: uppercase;*/
    
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 1.0);

    @media (max-width: 768px){
      font-size: 1.2rem;
    }
    
  }

  p {
    padding-left: 20px;
    font-size: clamp(1rem, 8vw, 2rem);
    margin-bottom: 2.5rem;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
`;

const HeroLogoImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;


// const HeroBtn = styled.div`  
//   align-items: center;
// `;

// const Arrow = styled(IoMdArrowRoundDown)`
//   margin-left: 0.5rem;
// `;


const Hero = ({ slides }) => {

  // const scrollMore = () => {
  //   scroll.scrollMore(660);
  // }

  return (
    <HeroSection>
      <HeroWrapper>
        {slides.map((slide, index) => {
          return (
            <HeroSlide key={index}>
              <HeroSlider>
                <HeroImage src={slide.image} alt={slide.alt} />
                <HeroContent>
                <HeroLogoImage src={HeadingTextImg} className='img-fluid' alt="hero-img" height={450} width={450} />
                <h1>{slide.tagLine}</h1>                  
                </HeroContent>
              </HeroSlider>
            </HeroSlide>
          )
        })}
      </HeroWrapper>
    </HeroSection>
  )
}


export default Hero;