import React from 'react'
import Location from './Location'

const Contact = () => {
  return (
    <div>

      <Location />
    </div>
  )
}

export default Contact