import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Vgrill from '../images/logo.jpeg';




const FloatingWhatsapp = () => {
  return (
    <div>
        <FloatingWhatsApp phoneNumber='+44 7823 871636' accountName='MR V-GRILL' avatar={Vgrill} />
    </div>
  )
}

export default FloatingWhatsapp