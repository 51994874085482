import React from 'react'
import { galleryData } from '../data/GalleryData'


function Gallery() {

  return (
    <div className='gallery-section pb-5'>
      <h1 className='gallery-title'>Gallery</h1>
      <div className='container'>
        <div className='row'>          
            {galleryData.map((item, index) => (
              <div className='col-md-3 pb-4'>
                <img className='img-fluid' src={item.image} alt={item.alt} key={index} />
              </div>              
            ))}          
        </div>
      </div>
    </div>
  )
}


export default Gallery;
