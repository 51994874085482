
import ImageOne from '../images/bg/bg-2.jpg';
// import ImageTwo from '../images/header2.jpg';

export const SliderData = [
    {
        title: '"We will Bring Care to your life"',
        tagLine: 'BURGER - GRILL - DRINKS',
        path: '/about',
        label: 'About Us',
        image: ImageOne,
        alt: 'House'
    },
    // {
    //     title: 'Luxury villa in Bali, Indonesia',
    //     price: '$4,280,000',
    //     path: '/homes',
    //     label: 'View Home',
    //     image: ImageTwo,
    //     alt: 'House'
    // }
];