import React from 'react'
import About from './About';
import Gallery from './Gallery';
import Location from './Location';
// import Contact from './Contact';
import Hero from './Hero';
import { SliderData } from "../data/SliderData";

const Home = () => {
  return (
    <div>

    <Hero slides={SliderData} />
    <About />
    <Gallery />
    <Location />
    {/* <Contact /> */}
    </div>
    
  )
}

export default Home