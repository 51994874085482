import React, { useState } from "react";
import styled from 'styled-components/macro';
import { IoMdClose } from 'react-icons/io';
import { HiOutlineBars3BottomRight } from 'react-icons/hi2';
// import { Link } from 'react-router-dom';
// import { menuData } from '../data/MenuData';

import './global.css';
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 30px;
  background-color: #222;
  // background-color: #f68713;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: 95%;
  height: 80px;
  border-radius: 15px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 99;
`;

const Logo = styled.h5`
  color: #fff;
  font-family: 'MigraLight';
  justify-self: start;
  cursor: pointer;
`;

const LogoResponsive = styled.h5`
  color: #fff;
  font-family: 'MigraLight';
  justify-self: start;
  cursor: pointer;
`;

const NavMenu = styled.ul``;

const NavMenuLink = styled(Link)`
text-decoration: none;
color: #fff;
padding: 0.7rem 1rem;
white-space: nowrap;
font-family: 'MigraBold';

&: hover{
  // background: rgb(80, 80, 238);
  color: #f68713;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 768px) {

  display: block;
  widows: 100%;
  padding: 2rem 0;
  color: #222;

      &:hover{
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(20px);
      }
}

`;


const ResponsiveMenu = styled.div`  
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }

`;

const MenuBars = styled(HiOutlineBars3BottomRight)`
  color: #fff;
`;

const CloseIcon = styled(IoMdClose)`
  color: #fff;
`;

const Navbar = () => {

  // Responsive Menu Class
  const [menuClick, setMenuClick] = useState(false);
  const handleClick = () => {
    if (menuClick !== true) {
      setMenuClick(true);
    } else {
      setMenuClick(false);
    }
  }

  // scroll to bottom
  const scrollToBottom = () => {
    scroll.scrollToBottom();
    setMenuClick(false);
  }

  const scrollToTop = () => {
    scroll.scrollToTop();
    setMenuClick(false);
  }

  const scrollToAbout = () => {
    scroll.scrollTo(720);
    setMenuClick(false);
  }

  const scrollToGallery = () => {
    setMenuClick(false);
    if (window.screen.width < 768) {
      scroll.scrollTo(1750);
    } else {
      scroll.scrollTo(1400);
    }   
  }


  return (
    <Nav>
      <LogoResponsive to='contact' className="responsive-logo">
        <Link style={{ color: "#fff", textDecoration: "none", fontSize: "16px" }} to='contact' onClick={scrollToTop}>
          34 HS, Hounslow, TW3 1NW
        </Link>
      </LogoResponsive>

      <Logo className="logo-text">
        <Link style={{ color: "#fff", textDecoration: "none" }} to='contact' onClick={scrollToTop}>
          Shop No-1, 34 High street, Hounslow, TW3 1NW
        </Link>
      </Logo>

      <ResponsiveMenu>
        <i onClick={handleClick}>
          {menuClick ? <CloseIcon /> : <MenuBars />}
        </i>
      </ResponsiveMenu>
      <NavMenu className={menuClick ? 'nav-menu active' : 'nav-menu'}>
        {/* {menuData.map((item, index) => (
          <NavMenuLink key={index} to={item.link}>{item.title}</NavMenuLink>
        ))} */}

        <NavMenuLink className="nav-link-primary" onClick={scrollToTop} to=''>Home</NavMenuLink>
        <NavMenuLink onClick={scrollToTop} to='menu'>Menu</NavMenuLink>
        <NavMenuLink onClick={scrollToAbout} to=''>About</NavMenuLink>
        <NavMenuLink onClick={scrollToGallery} to=''>Gallery</NavMenuLink>        
        <NavMenuLink onClick={scrollToBottom} to=''>Contact</NavMenuLink>
      </NavMenu>
    </Nav>
  )
}

export default Navbar;