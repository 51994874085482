import React, { useEffect } from 'react'
import styled from 'styled-components';
import { MrVBurgers, OurSpecialsitemsData, VeggieRangeitemsData, TheSaladsitemsData, SidesDrinksitemsData, PiriPiriitemsData, PlattersitemsData, DessertsitemsData, KidsitemsData } from '../data/ItemsData';
import Aos from "aos";
import "aos/dist/aos.css";

// import '../App.css';

const Menu = () => {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  return (
    <>
      <div className="menu-section">
        <div className="container">
          <h2 className='menu-title'>Menu</h2>

          {/* Menu 0 Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Mr. V Burgers</h3>
            <div className="row">
              {MrVBurgers.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <img className='img-fluid' src={item.image} alt="Item" />
                      </div>
                      <div className="col-md-8 col-12 d-flex flex-row align-items-center">
                        <div className='p-2'>
                          <ItemPrice>£ {item.price}</ItemPrice>
                          <ItemTitle>{item.title}  </ItemTitle>
                          <ItemText>{item.info}</ItemText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu 0 Section Ends */}


          {/* Menu 1 Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Our Specials</h3>
            <div className="row">
              {OurSpecialsitemsData.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <img className='img-fluid' src={item.image} alt="Item" />
                      </div>
                      <div className="col-md-8 col-12 d-flex flex-row align-items-center">
                        <div className='p-2'>
                          <ItemPrice>£ {item.price}</ItemPrice>
                          <ItemTitle>{item.title}  </ItemTitle>
                          <ItemText>{item.info}</ItemText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu 1 Section Ends */}

          {/* Menu 2 Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Veggie Range</h3>
            <div className="row">
              {VeggieRangeitemsData.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <img className='img-fluid' src={item.image} alt="Item" />
                      </div>
                      <div className="col-md-8 col-12 d-flex flex-row align-items-center">
                      <div className='p-2'>
                          <ItemPrice>£ {item.price}</ItemPrice>
                          <ItemTitle>{item.title}  </ItemTitle>
                          <ItemText>{item.info}</ItemText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu 2 Section Ends */}

          {/* Menu 3 Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>The Salads</h3>
            <div className="row">
              {TheSaladsitemsData.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <img className='img-fluid' src={item.image} alt="Item" />
                      </div>
                      <div className="col-md-8 col-12 d-flex flex-row align-items-center">
                        <div className='p-2'>
                          <ItemPrice>£ {item.price}</ItemPrice>
                          <ItemTitle>{item.title}  </ItemTitle>
                          <ItemText>{item.info}</ItemText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu 3 Section Ends */}

          {/* Menu 4 Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>The Sides & drinks</h3>
            <div className="row">
              {SidesDrinksitemsData.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        <img className='img-fluid' src={item.image} alt="Item" />
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                        <div className=''>
                          <ItemTitle>{item.title}  </ItemTitle>
                          <ItemPrice>£ {item.price}</ItemPrice>
                          <ItemText>{item.info}</ItemText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu 4 Section Ends */}


          {/* Menu 5 Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>The Piri Piri</h3>
            <div className="row">
              {PiriPiriitemsData.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-xl-4">
                        <img className='img-fluid' src={item.image} alt="Item" />
                      </div>
                      <div className="col-xl-7 d-flex flex-row align-items-center">
                        <div className='d-flex flex-column align-items-left p-1'>
                        <ItemPrice>{item.price}</ItemPrice>
                          <ItemTitle>{item.title}  </ItemTitle>                          
                          {/* <Button to='/services' onClick={scrollToTop} primary={true}>{item.link}<Arrow /></Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu 5 Section Ends */}

          {/* Menu 6 Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Platters</h3>
            <div className="row">
              {PlattersitemsData.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        <img className='img-fluid' src={item.image} alt="Item" />
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                        <div>
                        <ItemPrice>£ {item.price}</ItemPrice>
                          <ItemTitle>{item.title}</ItemTitle>                          
                          <ItemText>{item.info}</ItemText>
                          {/* <Button to='/services' onClick={scrollToTop} primary={true}>{item.link}<Arrow /></Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu 6 Section Ends */}

          {/* Menu 7 Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>The Desserts</h3>
            <div className="row">
              {DessertsitemsData.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4">
                        <img className='img-fluid' src={item.image} alt="Item" />
                      </div>
                      <div className="col-md-8 d-flex flex-row align-items-center">
                        <div className='p-1'>                        
                          <ItemTitle>{item.title}  </ItemTitle>

                          <ItemPrice>£ {item.price}</ItemPrice>                          
                          <ItemTitle>{item.size}  </ItemTitle>
                          
                          <ItemPrice> {item.price2}</ItemPrice>
                          <ItemTitle>{item.size2}  </ItemTitle>
                          {/* <Button to='/services' onClick={scrollToTop} primary={true}>{item.link}<Arrow /></Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu 7 Section Ends */}

          {/* Menu 8 Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Kids Meal</h3>
            <div className="row">
              {KidsitemsData.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <img className='img-fluid' src={item.image} alt="Item" />
                      </div>
                      <div className="col-md-8 col-12 d-flex flex-row align-items-center">
                        <div className='p-2'>
                          <ItemPrice>£ {item.price}</ItemPrice>
                          <div>
                            <UnorderedList>
                              <li>Strip burger, fries, drink and a toy</li>
                              <li>3 x wings, fries, drink and a toy</li>
                              <li>3 x strips, fries, drink and a toy</li>
                              <li>4x chicken nuggets, fries, drink and a toy</li>
                              <li>Crunchy Chicken burger, Fries drink and a toy</li>
                            </UnorderedList>
                          </div>
                          {/* <Button to='/services' onClick={scrollToTop} primary={true}>{item.link}<Arrow /></Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu 8 Section Ends */}


        </div>
      </div>
    </>
  )
}

export default Menu;



const ItemTitle = styled.h4`
padding: 5px;
  font-size: 0.9rem;
  text-align: left;
  font-weight: bold;
  font-family: 'MigraBold';
  color: #fff;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ItemPrice = styled.h4`
  padding-right: 15px;
  padding-top: 5px;
  font-size: 0.8rem;
  float: right;
  font-weight: bold;
  color: #fff;
`;

const ItemText = styled.p`  
  padding: 5px;
  font-size: 12px;
  text-align: left;
  color: #d1d1d1;

  // overflow: hidden;
  //  text-overflow: ellipsis;
  //  display: -webkit-box;
  //  -webkit-line-clamp: 2; /* number of lines to show */
  //          line-clamp: 2;
  //  -webkit-box-orient: vertical;
`;

const UnorderedList = styled.ul`
    color: #fff;
    text-align: left;

    &:hover{
      color: #f68713;
    }
`;